<template>
    <div>
        <hb-empty-state v-if="!property_id" message="No property selected"></hb-empty-state>

        <div v-if="property_id && selectedTab === 0" style="position: relative;" :style="{'height': drawerHeight}">
            <div class="sidebar-slider" v-show="showAssignSlide">
                <v-navigation-drawer absolute v-model="showAssignSlide" :height="drawerHeight" right hide-overlay width="50%"
                    class="merchandise-drawer" :class="{'merchandise-drawer-property' : showAssignSlide === 'edit'}">
                    <v-app-bar color="#E0F5F5" elevation="0">
                        <div class="title">
                            <span v-if="showAssignSlide === 'edit'">
                                <hb-icon class="ml-n1">mdi-square-edit-outline</hb-icon> Add Products to Inventory
                            </span>
                            <span v-if="showAssignSlide === 'download'">
                                <hb-icon class="ml-n1">mdi-square-edit-outline</hb-icon> Download
                            </span>
                        </div>
                        <v-spacer></v-spacer>
                        <v-row justify="end">
                            <hb-btn icon tooltip="Close" @click="hideSlideEvent" active-state-off>
                                mdi-close
                            </hb-btn>
                        </v-row>
                    </v-app-bar>
                    <div class="main-content-box">
                        <div v-if="showAssignSlide === 'download'">
                            <div class="description-box hb-text-night-light pa-4">
                                Create 2-3 sentences that describes the overview of this form.
                            </div>
                            <v-divider></v-divider>
                            <hb-form label="Download Report" class="download-report-form" full :divider="false" :style="{'height': `calc(${drawerHeight} - 118px)` }">
                                <hb-btn @click="downloadReport">Download</hb-btn>
                                <p class="hb-text-light mt-3">Inventory Report.csv</p>
                            </hb-form>
                        </div>
    
                        <div v-if="showAssignSlide === 'edit'">
                            <div class="description-box hb-text-night-light pa-4 white">
                                Select the products that you want to bulk edit on the left.
                            </div>
                            <!-- <div class="d-flex pa-4 white button-otion cursor-pointer" @click="bulkEditOption=''">
                                <hb-icon large>mdi-chevron-left</hb-icon>
                                <div class="btn-title pl-3">{{ bulkEditOption=='remove-inventory' ? 'Remove' : 'Add' }} Inventory</div>
                            </div> -->
                            <div class="bulk-buttons">
                                <v-row class="mx-0 px-2 py-2" style="border-bottom: 1px solid #e1e6eb;">
                                    <v-col><strong>SKU</strong></v-col>
                                    <v-col><strong>Name</strong></v-col>
                                    <v-col><strong>Qty Added</strong></v-col>
                                    <v-col><strong>Cost</strong></v-col>
                                </v-row>
    
                                <v-row v-for="(item, index) in selectedProducts" class="mx-0 px-2 bulk-edit-item" :key="index">
                                    <v-col>{{ item.sku }}</v-col>
                                    <v-col>{{ item.name }}</v-col>
                                    <v-col>
                                        <hb-text-field
                                            placeholder="0"
                                            v-model="item.new_quantity"
                                            type="number"
                                            v-validate="'min_value:1'"
                                            class="no-num-arrows"
                                            id="new_quantity"
                                            min="0"
                                            name="new_quantity"
                                            data-vv-name="new_quantity" data-vv-as="quantity"
                                            :error="errors.has('new_quantity')" 
                                        />
                                    </v-col>
                                    <v-col>
                                        <hb-text-field
                                            placeholder="0.00"
                                            v-model="item.new_price"
                                            type="number"
                                            class="no-num-arrows"
                                            v-validate="'min_value:1'"
                                            id="new_price"
                                            min="0"
                                            name="new_price"
                                            data-vv-name="new_price" data-vv-as="quantity"
                                            :error="errors.has('new_price')"
                                            @change="fractionalValueEvent($event, index, 'new_price')"
                                        >
                                        <template v-slot:prepend-inner>
                                            $
                                        </template>
                                    </hb-text-field>
                                    </v-col>
                                </v-row>

                                <hb-empty-state v-if="!selectedProducts.length" class="mx-5 mt-5"
                                    message="No products selected">
                                </hb-empty-state>
                            </div>
                        </div>
                    </div>
                    <v-app-bar color="white" class="footer-actions" absolute bottom elevation="0" v-if="showAssignSlide === 'edit'" >
                        <v-spacer></v-spacer>
                        <div>
                            <hb-link class="mr-2" @click="hideSlideEvent">Cancel</hb-link>
                            <!-- <hb-btn color="primary">Save</hb-btn> -->
                            <hb-btn color="primary" :disabled="isBulkLoading || !selectedProducts.length" :loading="isBulkLoading" @click="updateProductsBulkEvent">Save</hb-btn>
                        </div>
                    </v-app-bar>
                </v-navigation-drawer>
            </div>
            <hb-data-table 
                :search="search" 
                v-if="isLoadingData || property_merchandises.length" 
                :headers="tableColumns"
                :show-select="showAssignSlide === 'edit' ? true : false"
                v-model="selectedProducts"
                :loading="isLoadingData" 
                class="property-merchand-table"
                :class="{'frozen-column-table-property' : showAssignSlide !== 'edit' && !isLoadingData}"
                fixed-header
                :items="property_merchandises">
                <template v-slot:item.data-table-select="{ isSelected, select }">
                    <v-simple-checkbox color="primary" :value="isSelected" @input="select($event)"></v-simple-checkbox>
                </template>
    
                <template v-slot:item.sku="{ item }">
                    <hb-tooltip v-if="item.sku && item.sku.length > 10">
                        <template v-slot:item>
                            <span style="border-bottom: 1px dashed #101318; padding-bottom: 2px;">
                                {{ trimTextEvent({ value: item.sku, limit: 10 }) }}
                            </span>
                        </template>
                        <template v-slot:body>
                            {{ item.sku }}
                        </template>
                    </hb-tooltip>
                    <span v-else>{{ item.sku }}</span>
                </template>
    
                <template v-slot:item.name="{ item }">
                    <hb-tooltip v-if="item.name && item.name.length > 33">
                        <template v-slot:item>
                            <span style="border-bottom: 1px dashed #101318; padding-bottom: 2px;">
                                {{ trimTextEvent({ value: item.name, limit: 33 }) }}
                            </span>
                        </template>
                        <template v-slot:body>
                            {{ item.name }}
                        </template>
                    </hb-tooltip>
                    <span v-else>{{ item.name }}</span>
                </template>
                <!-- <template v-slot:item.description="{ item }">
                    <hb-tooltip v-if="item.description && item.description.length > 25"  max-width="500px">
                        <template v-slot:item>
                            <span style="border-bottom: 1px dashed #101318; padding-bottom: 2px;">
                                {{ trimTextEvent({ value: item.description, limit: 25 }) }}
                            </span>
                        </template>
                        <template v-slot:body>
                            {{ item.description }}
                        </template>
                    </hb-tooltip>
                    <span v-else>{{ item.description }}</span>
                </template> -->

                <template v-slot:item.default_price="{ item }">
                    <span>${{ item.default_price }}</span>
                </template>

                <template v-slot:item.default_cost="{ item }">
                    <span>${{ item.default_cost }}</span>
                </template>
                
                <template v-slot:item.total_cost_product="{ item }">
                    <span>${{ item.total_cost_product }}</span>
                </template>

                <template v-slot:item.last_inventory_update="{ item }">
                    <div class="text-center">
                        <hb-tooltip v-if="item.last_inventory_update">
                             <template v-slot:item>
                                 <hb-icon class="pr-2">
                                    mdi-account-clock-outline
                                 </hb-icon>
                             </template>
                             <template v-slot:body>
                                 <p class="mb-0">{{ item.last_inventory_update | formatLocalDateTimeServices }}</p>
                                 <p class="mb-0">By: {{ item.updated_by }}</p>
                             </template>
                         </hb-tooltip>
                    </div>
                </template>
                
                <template v-slot:item.status="{ item }">
                    <span v-if="item.statusMark === 'In Stock'">
                        <HbIcon mdi-code="mdi-check-circle" small color="#02AD0F" /> <strong
                            class="top-2px">{{ item.statusMark }}</strong>
                    </span>
                    <span v-if="item.statusMark === 'Out of Stock'" style="color: #FB4C4C">
                        <HbIcon mdi-code="mdi-alert" small color="#FB4C4C" /> <strong
                            class="top-2px">{{ item.statusMark }}</strong>
                    </span>
                    <span v-if="item.statusMark === 'Low Stock'">
                        <HbIcon mdi-code="mdi-alert-circle" small color="#FBC944" /> <strong
                            class="top-2px">{{ item.statusMark }}</strong>
                    </span>
                </template>

                <template v-slot:item.archived="{ item }">
                    <span v-if="item.archived">
                        <hb-icon class="pr-2" mdi-code="mdi-table-actions-disable"></hb-icon>
                    </span>
                    <span v-else>
                        <hb-icon class="pr-2" mdi-code="mdi-table-actions-enable"></hb-icon>
                    </span>
                </template>
    
                <template class="border-left left-border" v-slot:item.actions="{ item }">
                    <hb-menu options class="d-flex justify-center">
                        <v-list>
                            <v-list-item @click="updateInventoryEvent(item)">
                                <v-list-item-title>Update Inventory</v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="editProductEvent(item)">
                                <v-list-item-title>Edit Product</v-list-item-title>
                            </v-list-item>
                            <!-- <v-list-item @click="showDeleteDialogEvent(item)">
                                <v-list-item-title>Remove Product</v-list-item-title>
                            </v-list-item> -->
                            <v-list-item @click="archiveProductEvent(item)">
                                <v-list-item-title>{{ item.archived ? 'Activate' : 'Deactivate' }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </hb-menu>
                </template>
    
                <template v-if="isLoadingData || property_merchandises.length" v-slot:footer>
                    <div class="pa-3 pl-5 cm-footer">
                        Total Inventory Cost: <strong>${{ totalCost }}</strong>
                    </div>
                </template>
            </hb-data-table>
    
            <div class="no-data-box" v-if="!isLoadingData && !property_merchandises.length && property_id">
                <p class="hb-text-light mb-0">Assign merchandise to create property inventory.</p>
                <hb-btn class="mt-3 d-inline-block" @click="showAssignProductModalEvent">Assign Merchandise</hb-btn>
            </div>
    
            <hb-modal v-model="showAssignProductModal" size="large" title="Assign Products to this Property">
                <template v-slot:subheader>
                    <span class="hb-text-light">
                        Choose Merchandise you want to sell at this store
                    </span>
                </template>
                <template v-slot:content>
                    <v-col>
                        <hb-text-field v-model="searchProduct" v-validate="'required|max:45|email'" data-vv-scope="default"
                            data-vv-name="email" data-vv-as="Email" :error="errors.has('default.email')"
                            placeholder="Search Product" full box />
                    </v-col>
    
                    <hb-data-table :headers="corporateTableColumns" :loading="isLoadingCorporateData"
                        :items="corporateMerchandises" :show-select="true" v-model="selectedRows" fixed-header
                        :search="searchProduct" class="corporate-merchand-table-2 px-3 pb-3">
                        <template v-slot:item.data-table-select="{ isSelected, select }">
                            <v-simple-checkbox color="primary" :value="isSelected" @input="select($event)"></v-simple-checkbox>
                        </template>
                    </hb-data-table>
    
                </template>
                <template v-slot:right-actions>
                    <hb-btn color="primary" :disabled="isLoading($options.name) || !corporateMerchandises.length" :loading="isLoading($options.name)" @click="assignPropertiesEvent">Assign</hb-btn>
                </template>
            </hb-modal>
    
            <update-inventory v-model="showUpdateInventoryModal" v-if="showUpdateInventoryModal" :property-id="property_id"
                :inventory-update-selected="inventoryUpdateSelected" @fetchData="fetchData" />
            <edit-product v-model="showEditProductModal" v-if="showEditProductModal" :property-id="property_id"
                :selected="editSelected" @fetchData="fetchData" />
    
            <hb-modal v-model="deleteDialog" size="medium" :title="'Remove  ' + (deleteItem ? deleteItem.name : '')"
                confirmation :footerCancelOption="!hasInventory">
                <template v-slot:content>
                    <div v-if="!hasInventory">
                        <div class="pl-4 pr-3 py-4">
                            Are you sure you want to remove this product from your inventory?
                        </div>
                        <div class="pl-4 pr-3 pb-4">
                            This will delete all of the product’s saved settings and will set back to default settings when
                            added back.
                        </div>
                    </div>
                    <div v-else>
                        <div class="pl-4 pr-3 py-4">
                            Products can only be removed when its inventory is 0.
                        </div>
                    </div>
                </template>
                <template v-slot:right-actions>
                    <span v-if="!hasInventory">
                        <hb-btn color="destructive" :disabled="isDeleting" @click="deleteProductEvent">Remove</hb-btn>
                        <span v-show="isDeleting">
                            <loader color="#00b2ce" size="20px" class="inline-loader"></loader>
                        </span>
                    </span>
                    <span v-else>
                        <hb-btn color="destructive" @click="deleteDialog=false">Close</hb-btn>
                    </span>
                </template>
            </hb-modal>
            
        </div>
        <div v-if="property_id && selectedTab === 1" class="property-settings-merchandise">
            <v-expansion-panels v-model="selectedPanel">
                <hb-expansion-panel>
                    <template v-slot:title>
                        Merchandise Status Notifications
                    </template>
                    <template v-slot:actions>
                        <hb-switch v-model="statusEnabled" @input="updateSettings($event)" right>
                            <template v-slot:label>
                                 {{ statusEnabled ? 'On' : 'Off' }}
                            </template>
                        </hb-switch>
                    </template>
                    <template v-slot:content>
                        <hb-form label="Notification Schedule" @cancel="handleEmittedCancel" @save="handleEmittedSave" :active.sync="active" editable full>
                            <template v-slot:display v-if="reportData">
                                <p>Frequency of notification: {{ apiData.frequency }}</p>
                                <p>People to receive email notification:</p>
                                <div class="notification-emails pb-2">
                                    <div class="mb-0" v-for="item in apiData.emails">
                                        <hb-chip>{{ item }}</hb-chip>
                                    </div>
                                </div>
                                <p class="mt-3 mb-1">Document type: <span class="text-uppercase">{{ apiData.fileType }}</span></p>
                            </template>
                            <template v-slot:display v-else>
                                    <span class="hb-link pt-2 d-block">+ Click to Add</span>
                            </template>
                            <template v-slot:edit class="pb-3">
                                <p class="hb-text-light pb-2">Notification will include an inventory report with all products and their minimum orders.</p>
                                <p class="hb-text-light mb-4">1. Select how often to receive report notifications</p>
                                <HbSelect
                                    :items="scheduledFrequencies"
                                    v-model="reorderStatusData.frequency"
                                    v-validate="'required'"
                                    data-vv-name="order_frequency"
                                    data-vv-as="frequency"
                                    :error="errors.has('order_frequency')"
                                    placeholder="Select Frequency"
                                />

                                <p class="mt-3 pt-1 mb-0 hb-text-light">2.Select users to receive email notifications.</p>
                                <p class="ml-2 mb-4 hb-text-light">*People outside of the organization may also be added.</p>

                                <hb-combobox
                                    v-model="reorderStatusData.emails"
                                    v-validate="'required'"
                                    data-vv-name="order_emails"
                                    data-vv-as="emails"
                                    :error="errors.has('order_emails')"
                                    :items="adminsList"
                                    item-text="email"
                                    item-value="email"
                                    label="Enter name or email"
                                >
                                <template v-slot:selection="{item, attrs}">
                                    {{ item }}
                                </template>
                            </hb-combobox>

                                <p class="mb-4 mt-3 hb-text-light">Select the document type to be sent in the email.</p>

                                <v-row class="ma-0">
                                    <v-col class="pa-0 mb-n3">
                                        <hb-radio-group row v-model="reorderStatusData.fileType"
                                            id="fileType"
                                            name="fileType"
                                            data-vv-name="fileype"
                                            data-vv-as="filetype"
                                            :error="errors.collect('fileType').length > 0"
                                            :error-messages="errors.collect('fileType')"
                                            v-validate="'required'"
                                        >
                                            <hb-radio
                                                label="PDF"
                                                value="pdf"
                                            ></hb-radio>
                                            <hb-radio
                                                label=".CSV"
                                                value="csv"
                                            ></hb-radio>
                                        </hb-radio-group>
                                    </v-col>
                                </v-row>
                            </template>
                        </hb-form>
                    </template>
                </hb-expansion-panel>

                <hb-expansion-panel>
                    <template v-slot:title>
                        Low Stock Notification  
                    </template>
                    <template v-slot:actions >
                        <hb-switch v-model="lowStockEnabled" @input="updateSettings($event, 'low_notification')" right>
                            <template v-slot:label>
                                {{ lowStockEnabled ? 'On' : 'Off' }}
                            </template>
                        </hb-switch>
                    </template>
                    <template v-slot:content>
                        <hb-form label="Notifications" @editStart="showlowNotificationField=true" :description="showlowNotificationField ? 'Select users to receive an email notification whenever a product’s inventory is “Low Stock”. People outside of the organization may also be added.' : ''" @cancel="handleEmittedCancelLowStock" @save="handleEmittedSaveLowStock" :active.sync="showlowNotificationActive" editable full>
                            <template v-slot:display>
                                <div class="notification-emails pt-2" v-if="showlowNotificationField">
                                    <div class="mb-0" v-for="item in lowStockResponse.emails">
                                        <hb-chip>{{ item }}</hb-chip>
                                    </div>
                                </div>
                                <span class="hb-link d-block" v-else>+ Click to Add</span>
                            </template>
                            <template v-slot:edit>
                                <hb-combobox
                                    v-model="lowStockData.emails"
                                    :items="adminsList"
                                    item-text="email"
                                    return-object
                                    label="Enter name or email"
                                ></hb-combobox>
                            </template>
                        </hb-form>
                    </template>
                </hb-expansion-panel>
            </v-expansion-panels>
        </div>
    </div>
</template>

<script type="text/babel">

import api from "../../../assets/api";
import Loader from "../../assets/CircleSpinner.vue";
import HbDatePicker from '../../assets/HummingbirdDatepicker';
import { notificationMixin } from '@/mixins/notificationMixin.js';
import { downloadFile } from "../../../utils/common";
import moment from 'moment';

export default {
    components: {
        Loader,
        'update-inventory': () => import('./UpdateInventory.vue'),
        'edit-product': () => import('./EditPropertyProduct.vue'),
        HbDatePicker
    },
    name: "PropertyMerchandise",
    props: ['propertyId', 'search','showAssignSlide','selectedTab'],
    mixins: [notificationMixin],
    data() {
        return {
            setTaskOption: true,
            selectedPanel: 0,
            bulkEditOption: '',
            tableColumns: [
                { text: "SKU", value: "sku", width: "100px" },
                { text: "Name", value: "name", sortable: false, width: "250px" },
                // { text: "Description", value: "description", sortable: false, width: "200px" },
                { text: "Price", value: "default_price", sortable: false, width: "120px" },
                { text: "Cost", value: "default_cost", sortable: false, width: "120px" },
                { text: "Notification Threshold", value: "threshold", sortable: false, width: "80px" },
                { text: "Inventory", value: "total_inventory", sortable: false, width: "80px" },
                { text: "Total Cost of Product", value: "total_cost_product", sortable: false, width: "120px" },
                { text: "Last Inventory Update", value: "last_inventory_update", sortable: false, width: "180px" },
                { text: "Status", value: "status",width: "125px" },
                { text: "Active", value: "archived", sortable: false },
                { text: "", value: "actions", sortable: false }
            ],
            property_merchandises: [],
            isLoadingData: false,
            pagination: {},
            query: '',
            property_id: '',
            showAssignProductModal: false,
            corporateTableColumns: [
                { text: "SKU", value: "sku" },
                { text: "Name", value: "name", sortable: false },
                { text: "Default Price", value: "default_price", sortable: false },
                { text: "Default Cost", value: "default_cost", sortable: false },
            ],
            corporateMerchandises: [],
            selectedRows: [],
            selectedProducts: [],
            isLoadingCorporateData: true,
            showUpdateInventoryModal: false,
            showEditProductModal: false,
            inventoryUpdateSelected: {},
            editSelected: {},
            searchProduct: '',
            deleteDialog: false,
            isDeleting: false,
            deleteItem: null,
            totalCost: '',
            drawerHeight: '100%',
            isBulkLoading: false,
            scheduledFrequencies: [
                "End of Day",
                "End of Week",
                "End of Month",
                "End of Quarter",
                "End of Year",
            ],
            // frequencyList: [{id: 'weekly', name: 'Every 7 days (weekly)'}, {id: 'biweekly', name: 'Every 14 days (biweekly)'}, {id: 'monthly', name: 'Every 30 days (monthly)'}, {id: 'quarterly', name: 'Every 90 days (quarterly)'}],
            active: '',
            adminsList: [],
            selectedAdmins: [],
            reorderStatusData: {
                frequency: '',
                emails: [],
                fileType: '',
                enabled: true
            },
            apiData: {
                frequency: '',
                emails: [],
                fileType: ''
            },
            lowStockData: {
                emails: [],
                enabled: false
            },
            lowStockResponse: {
                emails: [],
                enabled: false
            },
            reportData: null,
            fetchedReports: [],
            statusEnabled: false,
            lowStockEnabled: false,
            showlowNotificationField: false,
            showlowNotificationActive: 'showlowNotification',
            reportFilters: null,
            tableHeight: '100%'
        }
    },
    created() {
        this.fetchAdmins();
        this.fetchReportStructure();
    },
    computed: {
        hasInventory() {
            return this.deleteItem && this.deleteItem.total_inventory ? true : false;
        },
        notificationFrequency() {
            let data = this.apiData.frequency;
            let dataFrequency = this.scheduledFrequencies.find(item => item.id == data);
            return dataFrequency ? dataFrequency.name : ''
        }
    },
    watch: {
        propertyId(newValue, oldValue) {
            this.property_id = newValue;
            this.fetchData();
            if (this.selectedTab === 1) {
                this.fetchDataOnChange();
            }
        },
        deleteDialog: function (newValue) {
            if (!newValue) {
                this.deleteItem = null;
            }
        },
        selectedTab: {
            handler: function (params) {
                if (params === 1) {
                    this.fetchDataOnChange();
                }
            }
        }
    },
    methods: {
        fetchDataOnChange: function() {
            this.fetchReportData();
            this.fetchSettings();
        },
        async fetchReportStructure() {
            let response = await api.get(this, api.REPORTS + 'structure?template=property_products');
            this.reportFilters = response.config.filters;
        },
        async updateSettings(params, type = '') {
            try {
                let payload = {
                    "category": "merchandiseManagement",
                    "property_ids": [this.propertyId],
                    "data": {
                        "allowMerchandiseStatusNotifications" : params ? true : false
                    }
                }

                if (type === 'low_notification') {
                    payload.data = {
                        "allowMerchandiseNotificationEmailsForLowStock" : { 
                            allowNotifications: this.lowStockEnabled ? true : false,
                            email: this.lowStockData.emails.length ? this.lowStockData.emails.map(item => {
                                if (typeof item === 'string') {
                                    return item
                                } else {
                                    return item.email;
                                }
                            }) : []
                        }
                    };
                    payload.data['allowMerchandiseNotificationEmailsForLowStock'] = JSON.stringify(payload.data['allowMerchandiseNotificationEmailsForLowStock']);
                }

                let response = await api.post(this, api.SETTINGS + '?category=merchandiseManagement', payload);
                this.showMessageNotification({ type: 'success', description: 'Settings saved successfully!' });
                this.fetchSettings()
            } catch (error) {
                this.showMessageNotification({
                    description: error
                });
            }
        },
        async fetchSettings() {
            if (this.propertyId) {
                let response = await api.get(this, api.SETTINGS + '?category=merchandiseManagement&property_id=' + this.propertyId);
                let enabled = response.settings.find(item => item.name === "allowMerchandiseStatusNotifications");
                this.reorderStatusData.enabled = enabled && (enabled.value === "1" || enabled.value === true) ? true : false;
                this.statusEnabled = enabled && (enabled.value === "1" || enabled.value === true) ? true : false;
                let allowMerchandiseNotificationEmailsForLowStock = response.settings.find(item => item.name === "allowMerchandiseNotificationEmailsForLowStock");
                if (allowMerchandiseNotificationEmailsForLowStock) {
                    let value = JSON.parse(allowMerchandiseNotificationEmailsForLowStock.value);
                    this.lowStockResponse = {
                        emails: value.email,
                        enabled: value.allowNotifications
                    };
                    if (value.email.length) {
                        this.showlowNotificationField = true;
                    }
                    // this.showlowNotificationActive = 'showlowNotification';
                    this.lowStockData.emails = value.email;
                    this.lowStockEnabled = value.allowNotifications;
                } else {
                    this.lowStockResponse = {
                        emails: [],
                        enabled: false
                    }
                    this.showlowNotificationField = false;
                    this.lowStockData.emails = [];
                    this.lowStockEnabled = false;
                }
                this.showlowNotificationActive = '';
            }
        },
        async fetchReportData(resetReports = false) {
            try {
                // this.isFetching = true;
                this.reportData = null;
                if (!this.fetchedReports.length || resetReports) {
                    let response = await api.get(this, api.REPOPRT_LIBRARY_SCHEDULED_REPORTS + '?source=merchandise');
                    this.fetchedReports = response;
                }
                if (this.propertyId) {
                    let report = this.fetchedReports.find(item => item.properties.find(pItem => pItem.id == this.propertyId));
                    if (report) {
                        this.reportData = report.reports[0];
                        this.apiData = {
                            frequency: report.frequency,
                            emails: [...report.send_to.map(item => item.email), ...report.emails_recipients.map(item => item.email)],
                            fileType: this.reportData.format === 'web' ? 'pdf' : 'csv',
                            report_period: this.reportData.report_period

                        };
                        this.reorderStatusData = {
                            frequency: report.frequency,
                            emails: [...report.send_to.map(item => item.email), ...report.emails_recipients.map(item => item.email)],
                            fileType: this.reportData.format === 'web' ? 'pdf' : 'csv',
                            enabled: true
                        }
                    } else {
                        this.apiData = {
                            frequency: '',
                            emails: [],
                            fileType: ''
                        };
                        this.reorderStatusData = {
                            frequency: '',
                            emails: [],
                            fileType: ''
                        }
                    }
                }
            } catch (error) {
                console.log('error :>> ', error);
                this.showMessageNotification({
                    description: error
                });
            } finally {
                // this.isFetching = false;
            }
        },
        searchReport: async function () {
            let response = await api.get(this, api.SEARCH_REPORT + 'Property Merchandise');
            return response;
        },
        saveReport: async function () {
            let searchResult = await this.searchReport();
            let reportResponse = searchResult.reports[0];
            let payload = {
                "share_report_title": "Property Merchandise - Manually Generated",
                "source": "merchandise",
                "frequency": this.reorderStatusData.frequency,
                "properties": [this.propertyId],
                "timeZone": moment.tz.guess(),
                "send_to": this.reorderStatusData.emails.filter(item => typeof item == "object").map(item => {
                    return {
                        contact_id: item.contact_id,
                        email: item.email,
                        first: item.first,
                        last: item.last,
                        status: item.status
                    }
                }),
                "emails_recipients": this.reorderStatusData.emails.filter(item => typeof item == "string").map(item => {
                    return {
                        email: item
                    }
                }),
                "sms_recipients": [],
                "reports": [
                    {
                        "format": this.reorderStatusData.fileType === 'pdf' ? 'web' : 'xlsx',
                        "name": "Property Merchandise",
                        "type": this.reorderStatusData.fileType === 'pdf' ? 'generic' : 'property_products',
                        "report_period": {
                            "label": null,
                            "start_date": this.apiData.report_period ? this.apiData.report_period.start_date : moment().format("YYYY-MM-DD"),
                            "end_date": this.apiData.report_period ? this.apiData.report_period.end_date : moment().format("YYYY-MM-DD"),
                        },
                        "filters": JSON.stringify(this.reportFilters),
                        "profile": 2,
                        "static_dynamic": "dynamic",
                        "report_type": this.reorderStatusData.fileType === 'pdf' ? "property_products" : null,
                        "report_id": reportResponse.id,
                        "collection_id": reportResponse.collection_id
                    }
                ]
            };
            try {
                if (this.reportData) {
                    let report = this.fetchedReports.find(item => item.properties.find(pItem => pItem.id == this.propertyId));  
                    await api.put(this, api.REPOPRT_LIBRARY_SCHEDULED_REPORTS + `/${report.id}`, { payload });
                } else {
                    await api.post(this, api.REPOPRT_LIBRARY_SCHEDULED_REPORTS, { payload });
                }
                this.active = '';
                this.showMessageNotification({ type: 'success', description: 'Report saved successfully!' });
                this.fetchReportData(true);
            } catch (error) {
                this.showMessageNotification({ description: error });
            } 
        },
        fractionalValueEvent(params, index, field) {
            this.selectedProducts[index][field] = parseFloat(params).toFixed(2);
        },
        setDrawerHeight() {
            let documentHeight = document.body.clientHeight;
            let tableElement = document.getElementsByClassName('property-merchand-table');
            if (tableElement && tableElement[0]) {
                let tableTop = tableElement[0].getBoundingClientRect().top;
                this.drawerHeight = `${Math.ceil((documentHeight - tableTop) - 11)}px`;
                this.$emit('set-sidebar-height', documentHeight - tableTop);
                this.tableHeight = `${Math.ceil((documentHeight - tableTop) - 78)}px`;
                const innerElement = tableElement[0].querySelector(".v-data-table__wrapper");
                innerElement.style.maxHeight = this.tableHeight;
            }
        },
        async fetchData() {
            try {
                this.hideSlideEvent();
                this.isLoadingData = true;
                this.property_merchandises = [];
                let path = api.PROPERTIES + (this.property_id) + '/products?type=product&source=property_products';
                let response = await api.get(this, path);
                if (!response?.products?.length) {
                    this.$emit('hide-filters', true);
                } else {
                    this.$emit('hide-filters', false);
                }
                this.property_merchandises = response?.products?.map(item => {
                    let summary_inventory = item.summary_inventory ? item.summary_inventory : null
                    let remaining_inventory = (summary_inventory && summary_inventory.remaining_inventory) ? summary_inventory.remaining_inventory : 0;
                    let statusMark = '';
                    if (remaining_inventory > item.inventory_threshold) {
                        statusMark = 'In Stock';
                    } else if (remaining_inventory <= item.inventory_threshold && remaining_inventory > 0) {
                        statusMark = 'Low Stock';
                    } else if (remaining_inventory <= 0) {
                        statusMark = 'Out of Stock';
                    }
                    let fullName = summary_inventory && (summary_inventory.modified_by_first || summary_inventory.modified_by_last) ? (summary_inventory.modified_by_first + " " + summary_inventory.modified_by_last) : '';
                    return {
                        id: item.id,
                        sku: item.sku,
                        product_id: item.product_id,
                        name: item.name,
                        description: item.description,
                        default_price: item.price ? item.price.toFixed(2) : 0,
                        default_cost: summary_inventory ? Number.parseFloat(summary_inventory.average_weighted_cost).toFixed(2) : 0,
                        threshold: item.inventory_threshold ? item.inventory_threshold : 0,
                        total_inventory: remaining_inventory,
                        total_cost_product: summary_inventory ? Number.parseFloat((summary_inventory.average_weighted_cost * remaining_inventory)).toFixed(2) : 0,
                        // last_inventory_update: (new Date()).toISOString().split('T')[0],
                        last_inventory_update: summary_inventory && summary_inventory.modified_at && moment(summary_inventory.modified_at).isValid() ? summary_inventory.modified_at : '',
                        statusMark: statusMark,
                        summary_inventory: summary_inventory ? summary_inventory : null,
                        properties: item.merchandise_details && item.merchandise_details.Properties ? item.merchandise_details && item.merchandise_details.Properties : [],
                        archived: item.is_archived ? true : false,
                        updated_by: fullName && fullName.trim() ? fullName.trim() : '',
                    }
                });
                this.totalCost = response.product_summary && (response.product_summary.total_cost !== 'null' || response.product_summary.total_cost !== null) ? Number.parseFloat(response.product_summary.total_cost).toFixed(2) : 0;
                if (isNaN(this.totalCost)) {
                    this.totalCost = 0;
                }
            } catch (error) {
                this.showMessageNotification({ description: error });
                console.error(error);
            } finally {
                this.isLoadingData = false;
                this.setDrawerHeight();
            }
        },
        async showAssignProductModalEvent() {
            try {
                this.showAssignProductModal = true;
                this.isLoadingCorporateData = true;
                let response = await api.get(this, api.MERCHANDISE);
                this.corporateMerchandises = response?.merchandise?.map(item => {
                    return {
                        id: item.id,
                        sku: item.sku,
                        product_id: item.product_id,
                        name: item.name,
                        default_price: item.price ? item.price : 0,
                        default_cost: item.default_cost ? item.default_cost : 0

                    }
                });
                this.corporateMerchandises = this.corporateMerchandises.filter(item => !this.property_merchandises.find(p_item => p_item.sku === item.sku))
            } catch (error) {
                this.showMessageNotification({ description: error });
            } finally {
                this.isLoadingCorporateData = false;
            }
        },
        async assignPropertiesEvent() {
            try {
                this.isLoadingCorporateData = true;
                let payload = {
                    Products: this.selectedRows.map(item => { return { id: item.product_id } }),
                    Properties: [{ id: this.property_id }],
                }
                await api.post(this, api.MERCHANDISE + 'assign', payload);
                this.selectedRows = [];
                this.showAssignProductModal = false;
                this.fetchData();
            } catch (error) {
                this.showMessageNotification({ description: error });
            } finally {
                this.isLoadingCorporateData = false;
            }
        },
        async updateInventoryEvent(params) {
            this.showUpdateInventoryModal = true;
            this.inventoryUpdateSelected = params;
        },
        editProductEvent(params) {
            this.editSelected = params;
            this.showEditProductModal = true
        },
        showDeleteDialogEvent(params) {
            this.deleteItem = params;
            this.deleteDialog = true;
        },
        async deleteProductEvent() {
            try {
                this.isDeleting = true;
                await api.delete(this, api.PROPERTIES, `${this.propertyId}/products/${this.deleteItem.id}`);
                this.deleteDialog = false;
                this.fetchData();
            } catch (error) {
                this.showMessageNotification({ description: error });
            } finally {
                this.isDeleting = false;
            }
        },
        trimTextEvent(params = {  value: '', limit: 10}) {
            let { value, limit } = params;
            if (value && value.length > limit)
                return value.substring(0, limit) + '...';
            return value;
        },
        async updateProductsBulkEvent() {
            const validationStatus = await this.$validator.validateAll();
            if (validationStatus) {
                try {
                    this.isBulkLoading = true;
                    let payload = {
                        "property_id": this.propertyId,
                        // "transaction_type": this.bulkEditOption === 'add-inventory' ? 'newly_added' : 'writeoff',
                        "transaction_type": 'newly_added',
                        "bulk_products" : this.selectedProducts.map(item => {
                            return {
                                "quantity": item.new_quantity,
                                "cost_per_item": item.new_price,
                                "product_id": item.id
                            }
                        })
                    }
                    console.log('payload :>> ', payload);
                    await api.post(this, api.MERCHANDISE + 'inventory-bulk-actions', payload);
                    this.hideSlideEvent();
                    this.fetchData();
                } catch (error) {
                    this.showMessageNotification({ description: error });
                } finally {
                    this.isBulkLoading = false;
                }
            }
        },
        async downloadReport() {
            try {
                let url = api.REPORTS + "download-pdf";
                let timeZone = moment.tz.guess();
                let filters = {
                    "search": {
                        "search": "",
                        "report_date": ""
                    },
                    "columns": [
                        {
                            "key": "property_name",
                            "width": 300,
                            "label": "Property Name",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 1,
                            "column_type": "string",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "product_sku",
                            "width": 200,
                            "label": "SKU",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 2,
                            "column_type": "string",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "product_name",
                            "width": 200,
                            "label": "Name",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 3,
                            "column_type": "string",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchanidse",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "property_product_price",
                            "width": 100,
                            "label": "Price",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 4,
                            "column_type": "money",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "inventory_summary_property_product_cost",
                            "width": 100,
                            "label": "Cost",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 5,
                            "column_type": "money",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "inventory_summary_property_product_inventory",
                            "width": 100,
                            "label": "Inventory",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 6,
                            "column_type": "number",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "inventory_summary_property_product_total_cost",
                            "width": 100,
                            "label": "Total Cost of Product",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 7,
                            "column_type": "money",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "property_product_notification_threshold",
                            "width": 100,
                            "label": "Notification Threshold",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 8,
                            "column_type": "number",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": "",
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "inventory_summary_merchandise_last_inventory_update",
                            "width": 300,
                            "label": "Last inventory update",
                            "input": "timeframe",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 9,
                            "column_type": "string",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": {
                            "label": "",
                            "days": 0,
                            "period": "",
                            "relation": "",
                            "start_date": "",
                            "end_date": ""
                            },
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        },
                        {
                            "key": "inventory_summary_property_product_stock_status",
                            "width": 100,
                            "label": "Status",
                            "input": "text",
                            "options": [],
                            "agg_method": "Count",
                            "sort": 10,
                            "column_type": "string",
                            "format": "",
                            "sortable": true,
                            "fixed": false,
                            "group": "merchandise",
                            "search": {
                            "label": "",
                            "days": 0,
                            "period": "",
                            "relation": "",
                            "start_date": "",
                            "end_date": ""
                            },
                            "editable": false,
                            "hide": false,
                            "header_tool_tip": false,
                            "aggregate_enable": false
                        }
                    ],
                    "sort": {
                        "field": "product_name",
                        "dir": "ASC"
                    },
                    "pivot_mode": {
                        "type": "",
                        "column": {},
                        "row": {},
                        "pivot_field": {},
                        "agg_method": ""
                    },
                    "groups": [],
                    "roles_id": [this.primaryRoleId]
                }
                let data = {
                    "filters": JSON.stringify(filters),
                    "type": "generic",
                    "report_type": "property_products",
                    "format": "web",
                    "name": "Merchandise Property",
                    "timeZone": timeZone,
                    "roles_id": [this.primaryRoleId],
                    "properties": [this.property_id],
                }
                this.showMessageNotification({ type: 'success', description: 'Your download should begin shortly.' });
                let response = await api.post(this, url, data);
                downloadFile({
                    content_type: 'application/pdf',
                    filename: "merchandise-report",
                    url: response.url,
                });
            } catch (error) {
                this.showMessageNotification({ description: error });
            }
        },
        hideSlideEvent() {
            this.selectedProducts = [];
            this.bulkEditOption = '';
            this.$emit('hide-slide');
        },
        handleEmittedCancel(label){
            this.reorderStatusData = {
                ...this.reorderStatusData,
                ...this.apiData
            }
        },
        async handleEmittedSave(label){
            let status = await this.$validator.validateAll();
            if(status) {
                console.log('reorderStatusData :>> ', this.reorderStatusData);
                // let response = await api.post(this, api.MERCHANDISE + 'reorder-status', this.reorderStatusData);
                // this.apiData = response.data;
                this.saveReport();
            }
        },

        handleEmittedCancelLowStock(label){
            if (!this.lowStockResponse.emails.length) {
                this.showlowNotificationField = false;
            }
        },
        async handleEmittedSaveLowStock(label){
            let status = await this.$validator.validateAll();
            if(status) {
                this.updateSettings('', 'low_notification');
            }
        },

        async fetchAdmins() {
            await api.get(this, api.ADMIN).then(r => {
                this.adminsList = r.admins.map(admin => {
                    return {
                        contact_id: admin.contact_id,
                        email: admin.email,
                        first: admin.first,
                        last: admin.last,
                        status: admin.status,
                        name: admin?.email
                        // name: admin?.first + ' ' + admin?.last
                    }
                });
            });
        },
        async archiveProductEvent(params) {
            try {
                this.isLoadingData = true;
                let payload = {
                    "archived": params.archived ? false : true
                }
                await api.put(this, api.PROPERTIES + `${this.propertyId}/products/${params.id}/archive`, payload);
                await this.fetchData();
            } catch (error) {
                this.showMessageNotification({ description: error });
            } finally {
                this.isLoadingData = false;
            }
        }
    },
    destroyed(){
        this.errors.clear();
        this.$validator.reset();
        this.$validator.errors.clear();
    }
}
</script>

<style lang="scss" scoped>
.no-data-box {
    padding: 30px 0;
    text-align: center;
    border: 1.5px dashed #ccc;
    border-radius: 3px;
}

.cm-footer {
    border: thin solid rgba(0, 0, 0, 0.12);
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
}

.merchandise-drawer {
    height: 100%;
    top: 36px;
    transform: translateX(0%);
    z-index: 11;
    width: 50%;
    box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12);
}

.top-2px {
    position: relative;
    top: 2px;
}

.button-option {
    border: 1px solid #C4CDD5;
    border-radius: 4px;
    background-color: #FFF;
}

.button-option .btn-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.btn-title {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
}

.bulk-edit-item {
    background-color: #FFF;
    border-bottom: 1px solid #e1e6eb;
}
.bulk-buttons {
    margin-bottom: 80px;
}
</style>

<style lang="scss">
.property-settings-merchandise .hb-form-edit-mode-main-padding {
    padding-bottom: 20px;
}

.download-report-form .row.no-gutters {
    height: 100%;
}

.frozen-column-table-property {
  table {
    > thead, > tbody {
      > tr {
        // Styling for the first, second, and last columns
        th:first-child,
        td:first-child,
        th:nth-child(2),
        td:nth-child(2),
        th:last-child,
        td:last-child {
          position: sticky !important;
          position: -webkit-sticky !important;
          z-index: 3 !important;
          background: white;

          &:first-child {
            left: 0 !important;
            max-width: 100px;
          }

          &:nth-child(2) {
            left: 100px !important;

            &::after {
              content: "";
              position: absolute;
              top: 0;
              right: 0;
              bottom: 0;
              width: 2px;
              background: rgba(0, 0, 0, 0.1);
              box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2);
              pointer-events: none;
            }
          }

          &:last-child {
            right: 0 !important;
            width: 45px;
            max-width: 45px;

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: 0;
              bottom: 0;
              width: 2px;
              background: rgba(0, 0, 0, 0.1);
              box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
              pointer-events: none;
            }
          }
        }

        // Adjusting z-index for header cells
        th:first-child,
        th:nth-child(2),
        th:last-child {
          z-index: 4 !important;
        }
      }
    }
  }
}
</style>